/*$color_nubiumgreenlight: #009DEB;*/
html.sidenav--active body {
  overflow-y: hidden;
}

#sidenav {
  position: absolute;
  left: 25px;
  top: 30px;
  width: 25em;
  margin: 0;
  padding: 0 15px 15px 0;
}
html.manual #sidenav {
  min-height: 100%;
  left: 0;
  top: 0;
  padding-right: 0;
  padding-bottom: 100px;
}
#sidenav .scrollcontainer {
  width: 100%;
  position: relative;
}
#sidenav.scrollcontainer::-webkit-scrollbar {
  width: 0px;
}
#sidenav .closebtn {
  display: none;
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
#sidenav .closebtn:before, #sidenav .closebtn:after {
  position: absolute;
  display: block;
  content: "";
  transform: rotate(45deg);
}
#sidenav .closebtn:before {
  border-top: 1px solid #4a4a4a;
  width: 15px;
  top: 14px;
  left: 7px;
}
#sidenav .closebtn:after {
  border-left: 1px solid #4a4a4a;
  height: 15px;
  top: 7px;
  left: 14px;
}
html.manual #sidenav .title {
  color: #ffffff;
  padding-bottom: 3px;
  line-height: 30px;
  font-size: 30px;
  font-weight: 600;
  text-transform: none;
  margin-bottom: 30px;
}
#sidenav .more {
  padding: 7px 24px 7px 36px;
  line-height: 20px;
  white-space: nowrap;
  height: 36px;
  border-radius: 18px;
  border: 1px solid #979797;
  display: inline-block;
  position: relative;
  background-color: #fff;
}
#sidenav .more > .icon {
  width: 10px;
  height: 10px;
  background-color: #009DEB;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -5px;
  left: 15px;
}
#sidenav .more > .icon:before {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #fff;
}
#sidenav .more > .icon:after {
  content: "";
  display: block;
  position: absolute;
  left: 4px;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #fff;
}
#sidenav ul {
  list-style-type: none;
  margin: 0;
  padding: 0 0 15px;
}
#sidenav a {
  text-decoration: none;
  color: #ffffff;
  font-size: 14px;
  line-height: 17px;
  transition: color 0.15s;
}
#sidenav a:hover {
  color: #444444;
}
#sidenav li {
  display: block;
  padding-left: 20px;
  margin-bottom: 4px;
  position: relative;
}
#sidenav li.active > a {
  color: #444444;
}
#sidenav li:before {
  content: "";
  display: block;
  position: absolute;
  top: 9px;
  left: 1px;
  width: 7px;
  height: 7px;
  border: 1px solid #444444;
  border-radius: 100%;
}
#sidenav ul.clients > li:before {
  border-radius: 0;
  border: 0 none;
  top: 3px;
  font: normal normal normal 16px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f105";
  color: #444444;
}
#sidenav ul.tree {
  counter-reset: li;
  padding-left: 20px;
  margin-left: 2em;
}
#sidenav ul.tree > li:before {
  border-radius: 0;
  border: 0 none;
  display: inline-block;
  content: counter(li) ".";
  counter-increment: li;
  display: inline-block;
  position: absolute;
  top: -3px;
  left: -20px;
  font-weight: 500;
}
#sidenav ul.tree > li.active:before, #sidenav ul.tree > li:hover:before {
  color: #444444;
}
#sidenav ul.tree ul > li:before {
  display: none;
}
#sidenav ul.tree ul {
  padding: 0;
  margin-top: 10px;
}
#sidenav ul.tree ul > li > a {
  font-size: 14px !important;
  padding-left: 0.5em;
}
#sidenav ul.tree > li, #sidenav ul.tree li {
  margin: 0;
  padding: 0;
}
#sidenav ul.tree li + li {
  margin-top: 10px;
}
#sidenav ul.tree a {
  display: block;
  font-size: 16px;
  font-weight: 500;
}
@media (max-width: 1200px) {
  #sidenav {
    position: relative;
    top: 0;
    left: 0;
    float: left;
    margin-left: calc(50% - 412px); /* 50% - (1084 / 2) - 130 */
  }
  html.manual #sidenav {
    position: absolute;
    margin-left: 0;
    left: -290px;
  }
}
@media (max-width: 1048px) {
  #sidenav {
    margin-left: 12.5%;
  }
}
@media (max-width: 1024px) {
  #sidenav {
    margin-left: 20px;
  }
}
@media (max-width: 736px) {
  #sidenav {
    display: none;
  }
}
html.manual #sidenav {
  position: fixed;
  float: left;
  height: 90vh;
  top: 75px; /* topbars space */
  bottom: 0;
  min-width: 300px;
  padding: 0px 0 65px;
  min-height: 0;
  z-index: 0; /* below footer */
}
html.manual #sidenav #sidenavsearchform {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}
html.manual #sidenav.scroll:before, html.manual #sidenav.scroll:after {
  content: "";
  display: none;
  position: absolute;
  left: 20px;
  right: 20px;
  height: 1px;
  background-color: #ccc;
}
html.manual #sidenav:before {
  top: 51px;
}
html.manual #sidenav:after {
  bottom: 150px;
}
html.manual #sidenav .scrollcontainer {
  position: relative;
  padding: 0 20px;
  max-height: 100%;
  overflow-y: auto;
  /* IE */
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
html.manual #sidenav .scrollcontainer::-webkit-scrollbar {
  background-color: #009DEB;
  width: 15px;
}
html.manual #sidenav .scrollcontainer::-webkit-scrollbar-thumb {
  border: 1px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
html.manual #sidenav .scrollcontainer {
  padding: 35px 20px 0 20px;
}
html.has_banner #sidenav {
  position: static; /* IE fix */
  position: sticky;
}
@media (max-width: 1460px) {
  html.manual #sidenav {
    left: 0px;
    height: 90vh;
    width: 100%;
    max-width: 25em;
    color: #fff;
    top: 70px;
  }
}
@media (max-width: 1024px) {
  html.manual #sidenav {
    display: none;
    left: -325px;
    background-color: #fff;
  }
  html.manual #sidenav .closebtn {
    display: block;
    z-index: 5;
  }
}
@media (max-width: 1024px) {
  html.manual #sidenav {
    top: 64px;
    position: fixed;
    z-index: 99999;
    color: #4A4A4A;
    height: auto;
    max-width: 330px;
  }
  html.manual #sidenav a, html.manual #sidenav .title {
    color: #4A4A4A;
  }
  #sidenav header.navheader {
    background-color: #fff;
    height: 64px;
    position: fixed;
    box-shadow: 0px -8px 20px 0px #000;
  }
  #sidenav header.navheader .logo {
    width: 15em;
    line-height: 40px;
  }
  #sidenav html.manual #sidenav {
    max-width: 25em;
    color: #444;
    top: 64px;
  }
  #sidenav html.manual #sidenav .title {
    color: #444 !important;
    width: 90%;
  }
}
#sidenavsearchform {
  margin: 15px 0;
  background-color: #fff;
  height: 30px;
  border-radius: 15px;
  position: relative;
}
#sidenavsearchform input, #sidenavsearchform button {
  -webkit-appearance: none;
  height: 30px;
  background-color: transparent;
  border-radius: 0;
  border: 0 none;
  outline: none;
}
#sidenavsearchform input {
  font-size: 15px;
  line-height: 20px;
  padding: 5px 40px 5px 20px;
  width: 100%;
}
#sidenavsearchform button {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  cursor: pointer;
}
@media (max-width: 1024px) {
  html.manual #sidenavsearchform {
    height: 32px;
    border: 1px solid #999;
  }
  html.manual #sidenavsearchform button {
    color: #4A4A4A;
  }
}

.sidenavtoggle {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  cursor: pointer;
  position: fixed;
  right: -60px;
  opacity: 0;
  font-size: 24px;
  text-align: center;
  padding-top: 13px;
  transition: right 0.3s, opacity 0.2s;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.2);
  color: #009DEB;
  background-color: #fff;
  top: 85%;
}

.sidenavtoggle.visible {
  opacity: 1;
  right: 20px;
}

@media screen and (max-width: 580px) {
  html.manual main {
    max-width: unset;
    width: 100%;
    padding: 0 20px 0 20px;
  }
  html.manual #sidenav {
    width: auto;
  }
}