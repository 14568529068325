.manual .banner {
  height: 30vh;
}

.banner {
  height: 50vh;
  min-height: 300px;
  position: relative;
  text-align: center;
  color: white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.banner--text {
  font-size: 4em;
  font-family: "Circular Std Bold", Source Sans Pro, Helvetica, sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}