/*$color_nubiumgreenlight: #009DEB;*/
body {
  /*padding-top: 148px;*/ /* space for header*/
}

header.navheader {
  background-color: #fff;
  height: 74px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20; /* on top of main */
}
header.navheader .logotopleft {
  position: fixed;
  top: 7px;
  left: 28px;
  z-index: 100;
  height: 67px;
  width: 160px;
}
header.navheader .logotopleft div {
  height: 60px;
  max-height: 60px;
  background-image: url("./../shared/assets/logo-bredel.png");
  background-size: cover;
}
header.navheader a {
  font-family: inherit;
}
html.fixedheader header.navheader {
  position: fixed;
  margin-top: -74px;
}
html.fixeddoubleheader header.navheader {
  position: fixed;
}
header.navheader .logo {
  display: inline-block;
  position: fixed;
  left: 30px;
  top: 18px;
  text-decoration: none;
  color: #444444;
  font-weight: 500;
  z-index: 2;
  white-space: nowrap;
}
header.navheader .logo > span {
  display: inline-block;
  position: absolute;
  padding-left: 55px;
  padding-top: 5px;
}
header.navheader .logo > span > small {
  font-weight: 200;
  font-size: inherit;
  line-height: inherit;
  color: #979797;
}
@media (max-width: 1024px) {
  header.navheader .logo > span > small {
    opacity: 1 !important;
    display: inline-block !important;
  }
}
header.navheader .logo > span.blue {
  clip: rect(0px, 500px, 36px, 0px);
}
header.navheader .logo .icon {
  display: inline-block;
  width: 42px;
  height: 36px;
  position: absolute;
  left: 0;
  top: 0;
  background-size: contain;
  background-repeat: no-repeat;
}
header.navheader .logo > span.white {
  clip: rect(36px, 500px, 36px, 0px);
  color: #fff;
}
@media (min-width: 1025px) {
  html.fixedheader header.navheader .logo {
    top: 92px;
    z-index: 1;
    position: absolute;
  }
  html.fixedheader header.navheader .logo:hover {
    color: #009DEB;
  }
}
header.navheader #menuwrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
header.navheader #menuwrapper .mobilemenu-toggle {
  color: #fff;
  display: inline-block;
  position: absolute;
  right: 3px;
  top: 11px;
  display: none;
  cursor: pointer;
  width: 48px;
  height: 48px;
}
header.navheader #menuwrapper .mobilemenu-toggle .icon {
  display: inline-block;
  width: 16px;
  height: 0;
  border-bottom: 2px solid #fff;
  position: relative;
  transition: transform 0.2s;
  top: 7px;
  left: 15px;
}
header.navheader #menuwrapper .mobilemenu-toggle .icon:before, header.navheader #menuwrapper .mobilemenu-toggle .icon:after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 0;
  border-bottom: 2px solid #fff;
  position: absolute;
  top: -6px;
  transition: transform 0.2s;
}
header.navheader #menuwrapper .mobilemenu-toggle .icon:after {
  top: 6px;
}
html.mobilemenu-show header.navheader #menuwrapper .mobilemenu-toggle .icon {
  transform: rotate(45deg);
}
html.mobilemenu-show header.navheader #menuwrapper .mobilemenu-toggle .icon:before {
  transform: rotate(90deg) translate(6px, 0);
}
html.mobilemenu-show header.navheader #menuwrapper .mobilemenu-toggle .icon:after {
  transform: rotate(90deg) translate(-6px, 0);
}
header.navheader #menuwrapper #mobilemetanav {
  color: #fff;
  border-top: 1px solid #5A5A5A;
  margin: 0 22px;
  padding-left: 38px;
  padding-top: 5px;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  display: none;
}
header.navheader #menuwrapper #mobilemetanav a {
  text-decoration: none;
  color: inherit;
  display: block;
  padding: 3px 10px 3px 30px;
  position: relative;
  margin-top: 15px;
}
header.navheader #menuwrapper #mobilemetanav a:hover {
  text-decoration: underline;
}
header.navheader #menuwrapper #mobilemetanav a.support > span {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid #fff;
  border-radius: 100%;
  text-align: center;
}
header.navheader #menuwrapper #mobilemetanav a.support > span:after {
  display: inline-block;
  content: "?";
  position: relative;
  top: -2px;
  font-size: 13px;
}
header.navheader #menuwrapper #mobilemetanav a > span {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 6px;
}
header.navheader #menuwrapper__scrollcontainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.header__topright {
  position: absolute;
  top: 0;
  padding-top: 20px;
  right: 15px;
  height: 100%;
  white-space: nowrap;
  color: #4A4A4A;
  font-size: 17px;
  font-weight: 200;
}
.header__topright a {
  text-decoration: none;
  color: inherit;
  transition: color 0.15s;
}
.header__topright a a:hover {
  text-decoration: underline;
}
@media (min-width: 1025px) {
  .header__topright a:hover {
    color: #009DEB;
  }
  html.fixedheader .header__topright {
    top: 73px;
    z-index: 1;
    color: #fff;
    transition: color 0.15s;
    max-height: 60px;
  }
  html.fixedheader .header__topright a:hover {
    color: #51C3F6;
  }
}

#topsearchform {
  height: 44px;
  width: 44px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  margin-left: 25px;
  position: relative;
  transition: width 0.3s;
  top: -3px;
}
html.topsearch--active #topsearchform {
  overflow: visible;
}
#topsearchform button {
  display: block;
  cursor: pointer;
  font-size: 18px;
  width: 44px;
  height: 44px;
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0 none;
  outline: none;
  position: absolute;
  left: 0;
  top: 0;
  transition: color 0.15s;
}
#topsearchform .close {
  width: 40px;
  height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  display: none;
  cursor: pointer;
  overflow: hidden;
  border-color: #000;
  transition: border-color 0.15s;
}
#topsearchform .close:before {
  content: "";
  display: inline-block;
  width: 20px;
  position: absolute;
  top: 21px;
  left: 8px;
  border-top-width: 2px;
  border-top-color: inherit;
  border-top-style: solid;
  transform: rotate(45deg);
}
#topsearchform .close:after {
  content: "";
  display: inline-block;
  height: 20px;
  position: absolute;
  top: 12px;
  left: 17px;
  border-left-width: 2px;
  border-left-color: inherit;
  border-left-style: solid;
  transform: rotate(45deg);
}
#topsearchform .close:hover {
  border-color: #009DEB;
}
#topsearchform input {
  border: 1px solid #E9E9E9;
  background-color: #F8F8F8;
  font-size: 16px;
  line-height: 20px;
  height: 100%;
  width: 100%;
  -webkit-appearance: none;
  border-radius: 0;
  outline: none;
  padding: 12px 50px 12px 50px;
  opacity: 0;
  transition: opacity 0.3s;
  overflow: hidden;
}
#topsearchform .wh-autocomplete-values {
  display: none;
}
#topsearchform .wh-autocomplete-values li {
  padding-left: 50px;
}
html.topsearch--active #topsearchform {
  width: 50vw;
}
html.topsearch--active #topsearchform .wh-autocomplete-values, html.topsearch--active #topsearchform .close {
  display: block;
}
html.topsearch--active #topsearchform input {
  opacity: 1;
}
@media (min-width: 1025px) {
  #topsearchform button:hover {
    color: #009DEB;
  }
  html.fixedheader #topsearchform {
    top: -3px;
  }
  html.fixedheader #topsearchform button {
    color: #fff;
  }
  html.fixedheader #topsearchform button:hover {
    color: #51C3F6;
  }
  html.fixedheader.topsearch--active #topsearchform button {
    color: #2486DB;
  }
}

#rfnav {
  font-size: 18px;
  line-height: 30px;
  font-weight: 200;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
   not supported by any browser */
  height: 74px;
  display: block;
  font-size: 14px;
  overflow: hidden;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  margin-top: -1px; /* IE11 background render fix: 1px offset */
}
#rfnav:before {
  content: "";
  display: block;
  position: absolute;
  top: 1px; /* IE11 background render fix */
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0.7;
}
#rfnav .centercontainer {
  position: relative;
  height: 100%;
}
#rfnav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-top: 20px;
  width: auto;
  display: inline-block;
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 130px;
}
#rfnav li {
  display: inline-block;
  padding: 3px 0;
}
#rfnav li + li {
  margin-left: 10px;
}
#rfnav li > a {
  text-decoration: none;
  display: inline-block;
  padding: 0 15px;
  height: 30px;
  border: 1px solid #999;
  border-radius: 15px;
  transition: all 0.1s;
  color: #fff;
}
#rfnav li > a:hover, #rfnav li.active > a {
  color: #fff;
  background-color: #2486DB;
  border-color: #2486DB;
}
@media (max-width: 1024px) {
  #rfnav {
    height: 64px;
  }
  #rfnav ul {
    padding-top: 14px;
    margin-left: 20px;
  }
}
@media (max-width: 736px) {
  #rfnav {
    height: 48px;
  }
  #rfnav ul {
    padding-top: 6px;
    margin-left: 15px;
  }
}

#mainnav {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0px -8px 20px 0px #000;
  color: #fff;
  height: 74px;
  padding: 26px 0 18px;
  font-size: 18px;
  line-height: 30px;
  white-space: nowrap;
  transition: opacity 0.2s;
  font-weight: 200;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
   not supported by any browser */
}
#mainnav ul {
  padding-left: 20em;
}
#mainnav > ul > li {
  display: inline-block;
  margin-right: 50px;
  text-transform: none;
}
#mainnav > ul > li > .children {
  display: none;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: height 0.2s;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  margin-top: -1px; /* IE11 background render fix: 1px offset */
  height: 0;
}
#mainnav > ul > li > .children ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
#mainnav > ul > li > .children li {
  text-transform: none;
  display: inline-block;
  padding: 3px 0;
}
#mainnav a {
  text-decoration: none;
  color: #444444;
  font-size: 20px;
  display: inline-block;
  transition: color 0.15s;
}
#mainnav a .arrows {
  display: none;
}
#mainnav li a:hover, #mainnav li.active > a {
  color: #009DEB;
}
@media (min-width: 1025px) {
  html.fixedheader.topsearch--active #mainnav > ul > li > a {
    display: none;
  }
}
@media (max-width: 1500px) {
  #mainnav > ul > li {
    margin-right: 3.333vw;
  }
}
@media (max-width: 1200px) {
  #mainnav > ul {
    margin-left: 0;
    padding-left: 188px;
  }
}

@media (max-width: 1024px) {
  body {
    padding-top: 64px; /* space for header*/
  }
  html.mobilemenu-beforeshow {
    overflow: hidden;
  }
  header.navheader {
    height: 64px;
    position: fixed;
    box-shadow: 0px -8px 20px 0px #000;
  }
  html.fixedheader header.navheader {
    margin-top: 0;
  }
  header.navheader .logo {
    left: 25px;
    top: 15px;
    color: #fff;
    font-size: 20px;
    width: 15em;
    line-height: 40px;
    transform: none !important; /* overrule js animation css */
  }
  header.navheader .logo > span {
    padding-top: 3px;
  }
  header.navheader .logo > .blue {
    display: none;
  }
  header.navheader .logo > .white {
    clip: auto !important;
    padding-left: 50px;
  }
  header.navheader .logo > .white > .icon {
    width: 36px;
    height: 32px;
  }
  header.navheader #menuwrapper__scrollcontainer {
    width: 320px;
    height: calc(100% - 64px);
    margin-top: 64px;
    display: none;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #444444;
  }
  html.mobilemenu-beforeshow header.navheader #menuwrapper__scrollcontainer {
    display: block;
    left: 100%;
    transition: left 0.2s;
  }
  html.mobilemenu-show header.navheader #menuwrapper__scrollcontainer {
    left: 0;
  }
  html.mobilemenu-beforeshow header.navheader #menuwrapper {
    height: 100vh;
    width: 320px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10; /* on top of rf nav */
  }
  html.mobilemenu-beforeshow header.navheader #menuwrapper:before {
    content: "";
    display: block;
    position: fixed;
    top: 64px;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0;
    transition: opacity 0.2s;
  }
  html.mobilemenu-show header.navheader #menuwrapper:before {
    opacity: 0.7;
  }
  header.navheader #menuwrapper #mobilemetanav {
    display: block;
  }
  header.navheader #menuwrapper .header__topright--withsearch + #mainnav {
    margin-top: 50px;
  }
  header.navheader #menuwrapper #mainnav {
    bottom: auto;
    margin-top: 0px;
    height: auto;
    position: relative;
    width: 320px;
    left: 0;
    background-color: transparent;
  }
  header.navheader #menuwrapper #mainnav > ul {
    padding-left: 22px;
  }
  header.navheader #menuwrapper #mainnav li {
    display: block;
  }
  header.navheader #menuwrapper #mainnav > ul > li {
    font-size: 16px;
    text-transform: uppercase;
    margin: 0;
  }
  header.navheader #menuwrapper #mainnav > ul > li > a {
    display: block;
    padding: 2px 0 2px 40px;
    position: relative;
  }
  header.navheader #menuwrapper #mainnav > ul > li > a .arrows {
    color: #f2f2f2;
    display: inline-block;
    position: absolute;
    top: 1px;
    font-size: 24px;
    left: 5px;
    transform-origin: 30% 60%;
    transition: transform 0.2s;
  }
  header.navheader #menuwrapper #mainnav > ul > li.showchildren .arrows {
    transform: rotate(90deg) translate(-5px, 4px);
  }
  header.navheader #menuwrapper #mainnav > ul > li > .children {
    position: relative;
    display: block;
  }
  header.navheader #menuwrapper #mainnav > ul > li > .children ul {
    padding: 0 0 0 35px;
  }
  header.navheader #menuwrapper #mainnav > ul > li.showchildren > .children {
    height: auto;
  }
  header.navheader #menuwrapper #mainnav > ul > li li {
    font-size: 14px;
  }
  header.navheader #menuwrapper .header__topright {
    padding-top: 0;
    width: 100%;
    left: 0;
    height: auto;
    right: auto;
  }
  header.navheader #menuwrapper .header__topright > a {
    display: none;
  }
  header.navheader #menuwrapper .header__topright #topsearchform {
    width: auto;
    margin: 0;
    display: block;
    margin-top: 6px;
    height: 44px;
    overflow: visible;
  }
  header.navheader #menuwrapper .header__topright #topsearchform:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 22px;
    right: 22px;
    border-bottom: 1px solid #fff;
  }
  header.navheader #menuwrapper .header__topright #topsearchform .wh-autocomplete-values {
    display: block;
    padding-top: 0;
    border-radius: 0;
  }
  header.navheader #menuwrapper .header__topright #topsearchform .wh-autocomplete-values li {
    padding-left: 62px;
  }
  header.navheader #menuwrapper .header__topright #topsearchform input {
    color: #fff;
    opacity: 1;
    width: 100%;
    background-color: transparent;
    border: 0 none;
    padding-right: 67px;
    padding-left: 62px;
  }
  header.navheader #menuwrapper .header__topright #topsearchform.suggestionsactive input, header.navheader #menuwrapper .header__topright #topsearchform input:focus {
    background-color: #f8f8f8;
    color: #000;
  }
  header.navheader #menuwrapper .header__topright #topsearchform.suggestionsactive button, header.navheader #menuwrapper .header__topright #topsearchform input:focus + button {
    color: #2486DB;
    opacity: 1;
  }
  header.navheader #menuwrapper .header__topright #topsearchform .close {
    display: none;
  }
  header.navheader #menuwrapper .header__topright #topsearchform button {
    left: auto;
    right: 7px;
    color: #fff;
    opacity: 0.8;
  }
  html.mobilemenu-show header.navheader #menuwrapper #mainnav, html.mobilemenu-show header.navheader #menuwrapper .header__topright {
    display: block;
  }
  header.navheader #menuwrapper .mobilemenu-toggle {
    display: block;
  }
  html.mobilemenu-show header.navheader #menuwrapper .mobilemenu-toggle .open {
    display: none;
  }
  html.mobilemenu-show header.navheader #menuwrapper .mobilemenu-toggle .close {
    display: inline-block;
  }
  #mainnav, .header__topright {
    display: none;
  }
}
@media (max-width: 736px) {
  body {
    padding-top: 48px; /* space for header*/
  }
  header.navheader {
    /*height: 48px;*/
  }
  header.navheader .logo {
    left: 15px;
    top: 11px;
  }
  header.navheader .logo > .white {
    padding-left: 40px;
  }
  header.navheader .logo > .white > .icon {
    width: 28px;
    height: 25px;
  }
  header.navheader #menuwrapper .mobilemenu-toggle {
    right: 0;
    top: 3px;
  }
  header.navheader #menuwrapper #menuwrapper__scrollcontainer {
    height: calc(100% - 48px);
    margin-top: 48px;
  }
  html.mobilemenu-beforeshow header.navheader #menuwrapper:before {
    top: 48px;
  }
}