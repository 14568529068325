/*$color_nubiumgreenlight: #009DEB;*/
header.withactivesubnav + main {
  padding-top: 130px;
}

main {
  background-color: #fff;
  position: relative;
  min-height: 100px;
  flex: 1 0 auto;
  padding-top: 40px;
}
#pathnav + main {
  padding-top: 0;
}
html.manual main {
  display: block;
  flex: none;
  padding-top: 25px !important;
  padding-bottom: 80px;
}
main:after {
  clear: both;
  content: "";
  display: block;
}
main > * {
  display: block;
  width: 100%;
}
main > * > div {
  padding-top: 2em;
  padding-right: 2em;
}
main > * > div p {
  margin-left: 4em;
}
.manual main > * > div {
  margin-left: 4em;
}
.manual main > * > div p {
  margin-left: 0;
}
html.manual main > * {
  padding: 0;
}
html.widecontent main > *:not(div) {
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1318px;
}
main > .fullwidthcontent {
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin: 0;
}
main .pagebottomnav:after {
  content: "";
  display: block;
  clear: both;
}
main .pagebottomnav > a {
  display: inline-block;
  text-decoration: none;
  height: 30px;
  float: left;
  background-color: #e9e9e9;
  color: #4a4a4a;
  line-height: 20px;
  padding: 5px 20px;
  text-align: right;
  border-radius: 15px;
  margin-top: 30px;
  margin-bottom: 100px;
  position: relative;
  transition: background-color 0.15s;
  font-size: 18px;
  width: 137px;
}
main .pagebottomnav > a:hover {
  background-color: #d9d9d9;
}
main .pagebottomnav > a .fa {
  position: absolute;
  top: 7px;
  left: 12px;
  color: #009DEB;
  font-size: 18px;
}
main .pagebottomnav > a.nextpage {
  text-align: left;
  float: right;
}
main .pagebottomnav > a.nextpage .fa {
  left: auto;
  right: 12px;
}
@media (max-width: 1460px) {
  html.manual main {
    width: calc(100vw - 25em);
    margin: 0 0 0 25em;
  }
}
@media (max-width: 1137px) {
  html.manual main {
    margin-left: auto;
    left: 0;
  }
  main html.manual main {
    max-width: unset;
    width: calc(100vw - 10em);
    margin: 0 5em 0 5em;
  }
  main #mainnav > ul {
    margin-left: 0;
    padding-left: 20em;
  }
}
@media (max-width: 1084px) {
  main > * {
    padding-left: 12.5%;
    padding-right: 12.5%;
  }
}
@media (max-width: 1024px) {
  main {
    padding-top: 30px;
  }
  html.manual main {
    width: 100%;
    padding: 0 2.5em 0 1.5em;
  }
  main > *, html.widecontent main > *:not(div) {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 0;
  }
}
@media (max-width: 736px) {
  html.manual main {
    width: 100%;
    padding: 0 1.5em;
  }
  main {
    padding-top: 20px;
    width: 100%;
  }
  main > *, html.widecontent main > *:not(div) {
    padding-left: 15px;
    padding-right: 15px;
  }
  main > * {
    margin-left: 0;
  }
  main > * > div {
    margin-left: 0 !important;
  }
}

header.withactivesubnav + main {
  padding-top: 104px; /* 74px extra space for visible subnav in header*/
}

@media (max-width: 1024px) {
  header.withactivesubnav + main {
    padding-top: 85px; /* remove extra space for visible subnav in header*/
  }
}
@media (max-width: 736px) {
  header.withactivesubnav + main {
    padding-top: 63px; /* remove extra space for visible subnav in header*/
  }
}
.moreclientcases {
  clear: both;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  max-width: 100%;
  background-color: #009DEB;
  padding-top: 30px;
  padding-bottom: 25px;
  color: #fff;
}
.moreclientcases .grid8width:after {
  clear: both;
  content: "";
  display: block;
}
.moreclientcases .title {
  font-family: "DIN Next W01", Sans-Serif, Arial;
  font-weight: 300;
  text-transform: uppercase;
  padding-bottom: 10px;
  font-size: 18px;
  line-height: 28px;
  width: 50%;
  float: left;
}
.moreclientcases a {
  color: inherit;
  text-decoration: none;
  line-height: 21px;
}
.moreclientcases a:hover {
  text-decoration: underline;
}
.moreclientcases ul {
  float: left;
  display: block;
  width: 50%;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.moreclientcases ul li {
  display: block;
  position: relative;
  padding-left: 15px;
  padding-bottom: 5px;
}
.moreclientcases ul li .fa {
  position: absolute;
  left: 0;
  top: 2px;
}
@media (max-width: 736px) {
  .moreclientcases .title, .moreclientcases ul {
    float: none;
    width: 100%;
  }
}

.relatedcases {
  clear: both;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  max-width: 100%;
  background-color: #f2f2f2;
  padding-top: 30px;
  padding-bottom: 30px;
}
.relatedcases > .grid8width > .title {
  font-family: "DIN Next W01", Sans-Serif, Arial;
  color: #009DEB;
  font-weight: 500;
  text-transform: uppercase;
  padding-bottom: 10px;
  font-size: 18px;
  line-height: 36px;
}