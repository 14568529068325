@import url("https://use.fontawesome.com/releases/v5.2.0/css/all.css");
/*$color_nubiumgreenlight: #009DEB;*/
/* Fonts */
@font-face {
  font-family: "Circular Std Bold";
  font-style: normal;
  font-weight: normal;
  src: url("./shared/fonts/circularstd-bold.woff") format("woff");
}
@font-face {
  font-family: "Circular Std Book";
  font-style: normal;
  font-weight: normal;
  src: url("./shared/fonts/circularstd-book.woff") format("woff");
}
/* Global page, HTML5 setup, style reset */
html, body, p, h1, h2, h3, h4, h5, h6, form {
  padding: 0;
  margin: 0;
}

img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block;
}

audio, canvas, video {
  display: inline-block;
}

datalist, template {
  display: none;
}

*, *::before, *::after {
  -moz-box-sizing: border-box; /* Firefox version 28- (including 24 ESR) */
  -webkit-box-sizing: border-box; /* Android Browser version 3- */
  box-sizing: border-box;
}

html {
  background-color: #fff;
  display: flex;
  flex-direction: row; /* IE10/11 Workaround for child elements with flex-direction: column to outfill content area (main) */
  min-height: 100%;
  scroll-behavior: smooth;
}

html.manual {
  background-color: #009DEB;
}

html.manual > body {
  position: relative;
}

body {
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  padding-top: 74px;
  overflow-x: hidden;
  display: flex;
  width: 100vw;
  min-height: 100vh;
  flex-direction: column;
}

body, input, select, textarea {
  font-family: "Circular Std Book", Source Sans Pro, Helvetica, sans-serif;
  color: #444;
  font-weight: 100;
  letter-spacing: 0.2px;
}

main h1 {
  font-size: 40px;
}
main h2 {
  color: #009DEB;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  font-size: 25px;
}
main h3 {
  margin-bottom: 0.5em;
}

h1, h2, h3 {
  font-family: "Circular Std Bold", Source Sans Pro, Helvetica, sans-serif;
  color: #000;
  letter-spacing: -0.02em;
}

a {
  text-decoration: none;
  color: #009DEB;
}

html.manual main {
  max-width: none;
  width: calc(100vw - 25em);
  margin: 0 0 0 25em;
}

.pagelisting .summary {
  padding: 0;
  height: 100%;
}
.pagelisting .summary li {
  list-style-type: none;
  width: calc(33.3333333333% - 20px);
  height: 150px;
  margin: 10px;
  position: relative;
  float: left;
  background-color: #009DEB;
  border-radius: 10px;
}
@media screen and (max-width: 736px) {
  .pagelisting .summary li {
    width: 100%;
    margin: 10px 0 10px 0;
  }
}
.pagelisting .summary li a {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagelisting .summary li .title {
  color: #ffffff;
  font-family: "Circular Std Bold", Source Sans Pro, Helvetica, sans-serif;
  font-size: 1.5em;
}

.centercontainer {
  list-style-type: none;
  display: block;
  width: 100%;
  padding: 0 30px;
  /*max-width: 1084px;*/ /* 1024 + 2x 30px */
  margin: 0 auto;
  text-align: right;
}

.wh-rtd__img {
  margin: 1em;
  max-width: 90%;
}

.emb-textimagecols--scaled .emb-textimagecols__image {
  display: block;
  position: static;
  width: 100%;
  height: auto;
}

.normal {
  overflow: hidden;
  text-overflow: ellipsis;
}

#pathnav {
  width: none;
  display: inline-block;
}

@media (max-width: 1024px) {
  .centercontainer {
    padding: 0 20px;
    max-width: 1064px; /* 1024 + 2x 20px */
  }
  .wh-rtd__img {
    margin: 0em;
  }
}
@media (max-width: 736px) {
  .centercontainer {
    padding: 0 15px;
  }
}
.grid8width {
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1084px; /* 1024 + 2x 30px */
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .grid8width {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 736px) {
  .grid8width {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.mediaheader {
  position: relative;
  font-family: "DIN Next W01", Sans-Serif, Arial;
}
.mediaheader .previouspage, .mediaheader .nextpage {
  font-weight: 200;
  color: #fff;
  position: absolute;
  top: 50%;
  text-decoration: none;
  background-color: rgba(74, 74, 74, 0.85);
  height: 72px;
  margin-top: -36px;
  display: flex;
  padding: 10px 15px;
  font-size: 14px;
  align-items: middle;
  min-width: 150px;
  max-width: 210px;
  align-items: center;
  transition: background-color 0.15s;
}
.mediaheader .previouspage .fa, .mediaheader .nextpage .fa {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  font-size: 24px;
  padding: 24px 10px;
  background-color: rgba(0, 0, 0, 0.3);
  width: 30px;
}
.mediaheader .previouspage:hover, .mediaheader .nextpage:hover {
  background-color: rgb(74, 74, 74);
}
.mediaheader .previouspage {
  padding-left: 45px;
  border-radius: 0 7px 7px 0;
  left: 0;
}
.mediaheader .previouspage .fa {
  left: 0;
}
.mediaheader .nextpage {
  padding-right: 45px;
  border-radius: 7px 0 0 7px;
  right: 0;
}
.mediaheader .nextpage > .title {
  display: block;
  width: 100%;
  text-align: right;
}
.mediaheader .nextpage .fa {
  right: 0;
}
@media (max-width: 1024px) {
  .mediaheader .previouspage, .mediaheader .nextpage {
    display: none;
  }
}
.mediaheader .wh-carrousel__items__item {
  background-position: 0 0;
  background-repeat: no-repeat;
  min-height: 300px;
}
.mediaheader .wh-carrousel__items__item__link {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-decoration: none;
}
.mediaheader .wh-carrousel__items__item.active .wh-carrousel__items__item__link {
  display: block;
}
.mediaheader .wh-carrousel__items__item:before {
  content: "";
  padding-top: 24.3%; /* 1440 x 350 */
  display: block;
}
@media (max-width: 736px) {
  .mediaheader .wh-carrousel__items__item {
    min-height: 175px;
  }
}
.mediaheader .wh-carrousel--large .wh-carrousel__items__item:before {
  padding-top: 37.5%; /* 1440 x 540 */
}
.mediaheader .wh-carrousel--small {
  background-position: 50% 50%;
  background-size: cover;
}
.mediaheader .wh-carrousel--large .textoverlay {
  padding-bottom: 20px; /* extra space for jump buttons */
}
.mediaheader .wh-carrousel--large.wh-carrousel--single .textoverlay {
  padding-bottom: 0;
}
.mediaheader .textoverlay {
  white-space: normal;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: #fff;
  transform: translate3d(0, 0, 0);
}
.mediaheader .textoverlay:before {
  /* gradient */
  content: "";
  display: block;
  left: 0;
  right: 0;
  bottom: 0;
  height: 75%;
  position: absolute;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.mediaheader .textoverlay .centercontainer {
  position: relative;
  height: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  align-items: center;
}
@media (max-width: 1024px) {
  .mediaheader .textoverlay .centercontainer {
    padding-bottom: 2vw;
  }
}
.mediaheader .textoverlay .centercontainer.text--bottom h1 {
  align-self: flex-end;
}
.mediaheader .textoverlay h1 {
  font-size: 48px;
  line-height: 54px;
  padding-left: 100px;
  font-family: inherit;
}
html.client .mediaheader .textoverlay h1 {
  font-weight: 200;
}
.mediaheader .textoverlay h1 small {
  display: block;
  font-weight: 200;
  font-size: 24px;
  line-height: 28px;
}
.mediaheader .textoverlay h1 span + small {
  font-size: inherit;
  line-height: inherit;
}
@media (max-width: 1024px) {
  .mediaheader .textoverlay h1 {
    padding-left: 0px;
  }
}
@media (max-width: 1000px) {
  .mediaheader .textoverlay h1 {
    font-size: 4.8vw;
    line-height: 5.4vw;
  }
  .mediaheader .textoverlay h1 span + small {
    font-size: inherit;
    line-height: inherit;
  }
}
@media (max-width: 500px) {
  .mediaheader .textoverlay h1 {
    font-size: 24px;
    line-height: 26px;
  }
  .mediaheader .textoverlay h1 small {
    font-size: 18px;
    line-height: 18px;
  }
  html.topic .mediaheader .textoverlay h1 {
    font-size: 4.8vw;
    line-height: 5.4vw;
  }
}
html.topic .mediaheader .textoverlay h1 {
  font-weight: 300;
}
html.topic .mediaheader .textoverlay h1 span {
  display: inline-block;
  height: 82px;
  padding: 17px 45px 0 95px;
  border-radius: 41px;
  border: 2px solid #fff;
  position: relative;
}
html.topic .mediaheader .textoverlay h1 span:before {
  content: "";
  position: absolute;
  display: block;
  top: 28px;
  left: 24px;
  border: 2px solid #fff;
  border-radius: 100%;
  width: 23px;
  height: 23px;
}
html.topic .mediaheader .textoverlay h1 small {
  padding-left: 95px;
  margin-top: 5px;
  font-size: 30px;
}
@media (max-width: 1024px) {
  html.topic .mediaheader .textoverlay h1 span {
    height: 10.222vw;
    padding: 2.3vw 5vw 0 10vw;
  }
  html.topic .mediaheader .textoverlay h1 span:before {
    top: 3.5vw;
    left: 3vw;
    width: 2.55vw;
    height: 2.55vw;
  }
  html.topic .mediaheader .textoverlay h1 small {
    padding-left: 10vw;
    font-size: 4vw;
  }
}

.withactivesubnav + .mediaheader .textoverlay {
  top: 74px;
}

@media (max-width: 1024px) {
  .withactivesubnav + .mediaheader .textoverlay {
    top: 60px;
  }
}
@media (max-width: 736px) {
  .withactivesubnav + .mediaheader .textoverlay {
    top: 24px;
  }
}
.mediaheader__map {
  position: relative;
  height: 350px;
  z-index: 0;
}

.mediaheader__mapoverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  pointer-events: none;
}
.mediaheader__mapoverlay .textoverlay:before {
  display: none;
}
.mediaheader__mapoverlay .directions {
  pointer-events: all;
  display: inline-block;
  position: absolute;
  top: 40px;
  left: 50px;
  color: inherit;
  text-decoration: none;
  padding: 17px 120px 13px 20px;
  line-height: 20px;
  background-color: #fff;
  opacity: 0.9;
  z-index: 1000;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.31);
  border-radius: 1px;
  transition: opacity 0.15s;
}
.mediaheader__mapoverlay .directions:hover {
  opacity: 1;
}
.mediaheader__mapoverlay .directions .fa {
  position: absolute;
  right: 20px;
  top: 15px;
  font-size: 17px;
}

@media (max-width: 1024px) {
  .mediaheader__map {
    height: 38vw;
  }
  .mediaheader__mapoverlay .directions {
    left: 20px;
    top: 25px;
  }
}
@media (max-width: 736px) {
  .mediaheader__map {
    height: 190px;
  }
  .mediaheader__mapoverlay .directions {
    left: 15px;
    top: 15px;
    padding: 10px 65px 10px 15px;
  }
  .mediaheader__mapoverlay .directions .fa {
    top: 10px;
    right: 15px;
  }
}
#pathnav {
  background-color: #ffffff;
  width: 100%;
  /*max-width: 884px;*/
  margin-left: 2em;
  display: block;
  position: relative;
  left: 0;
}
#pathnav ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
#pathnav ol li {
  display: inline-block;
  font-size: 1.3em;
  line-height: 20px;
  color: #009DEB;
}
#pathnav ol li + li:before {
  content: "/";
  display: inline-block;
  padding: 0 8px;
  color: #009DEB;
  font-size: 15px;
}
#pathnav ol a {
  color: inherit;
  text-decoration: none;
}
#pathnav ol a:hover {
  text-decoration: underline;
}
@media (max-width: 1084px) {
  html #pathnav {
    margin-left: 12.5%;
    margin-right: 12.5%;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 1024px) {
  html #pathnav {
    margin-left: 0;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 1460px) {
  html.manual #pathnav {
    margin-right: auto;
    /*padding-left: 30px;*/
  }
  html.manual #pathnav #pathnav {
    margin-left: 2em;
  }
}
@media (max-width: 1137px) {
  html.manual #pathnav {
    margin-left: auto;
  }
}
@media (max-width: 736px) {
  html #pathnav {
    padding-left: 15px;
    padding-right: 15px;
  }
  html.manual #pathnav {
    padding-left: 0;
  }
  #pathnav .wh-rtd__img {
    margin: 0;
  }
}

header.withactivesubnav + #pathnav {
  padding-top: 74px; /* 74px extra space for visible subnav in header*/
}

@media (max-width: 1024px) {
  header.withactivesubnav + #pathnav {
    padding-top: 60px; /* remove extra space for visible subnav in header*/
  }
}
@media (max-width: 736px) {
  header.withactivesubnav + #pathnav {
    padding-top: 43px; /* remove extra space for visible subnav in header*/
  }
}
.wh-anchor {
  height: 80px;
  margin-top: -80px;
}